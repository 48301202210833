import React from 'react';

const USPSection: React.FC = () => {
  return (
    <div className="usp-section">
      <h2>How OrkDM Works</h2>
      <div className="usp-grid">
        <div className="usp-card">
          <h3>True Long Term Memory</h3>
          <p>
            The AI uses an advanced search engine to store and recall previous events from your campaign.
          </p>
        </div>
        <div className="usp-card">
          <h3>Complete Freedom</h3>
          <p>
            Play with your full creativity and wit. The AI does its best to integrate any action you attempt in a D&D session.
          </p>
        </div>
        <div className="usp-card">
          <h3>Integrated Game Mechanics</h3>
          <p>
            The AI is integrated with a traditional game system. This means it keeps track of everything for you: turn order, HP, ability scores, spell slots, etc.
          </p>
        </div>
        <div className="usp-card">
          <h3>Character Sheet Tracking</h3>
          <p>
            The AI helps you create and track your whole D&D 5e character sheet. No need to study before the game.
          </p>
        </div>
        <div className="usp-card">
          <h3>Inventory System</h3>
          <p>
            The AI keeps track of your items and what you have equipped at any given moment. This includes unique or crafted items.
          </p>
        </div>
        <div className="usp-card">
          <h3>Bend the Rules</h3>
          <p>
            The AI helps you homebrew anything on top of the standard D&D 5e rules and mechanics.
          </p>
        </div>
      </div>
    </div>
  );
};

export default USPSection;
