import React from 'react';
import TopBanner from './TopBanner';
import USPSection from './USPSection';
import './styles.css';
import CTASection from './CTASection';

const LandingPage: React.FC = () => {
  return (
    <div className="landing-page">
      <div className="left-section">
        <TopBanner />
        <CTASection />
      </div>
      <div className="right-section">
        <USPSection />
      </div>
    </div>
  );
};

export default LandingPage;
