import React, { useState } from 'react';

const CTASection: React.FC = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setErrorMessage('');
    setSuccessMessage('');
  };

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleJoinWaitlist = async () => {
    if (!isPrivacyPolicyChecked) {
      setErrorMessage('You must agree to our Privacy Policy.');
      setSuccessMessage('');
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
      setSuccessMessage('');
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_WAITLIST_API_URL;
      const url = new URL(`${apiUrl}/api/v1/waitlist/signup`);
      url.searchParams.append('email', email);

      const headers = {
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_WAITLIST_API_KEY || ''
      };

      const response = await fetch(url.toString(), {
        method: 'POST',
        headers: headers,
      });

      if (response.ok) {
        setSuccessMessage('Thank you for joining the Waitlist!');
        setErrorMessage('');
        setEmail('');
        // Add any additional logic here after a successful submission
      } else {
        console.error('Error submitting email:', response.status);
        setErrorMessage('There was an error submitting your email. Please try again later.');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('There was an error submitting your email. Please try again later.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="waitlist-section">
      <p>Join the waitlist to get an Early Access key</p>
      <div className="waitlist-form">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={handleInputChange}
        />
        <button onClick={handleJoinWaitlist}>Join Waitlist</button>
      </div>

      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}

      <div className="privacy-policy">
        <input
          type="checkbox"
          checked={isPrivacyPolicyChecked}
          onChange={(e) => setIsPrivacyPolicyChecked(e.target.checked)}
        />
        <label htmlFor="privacyPolicy">
          I have read and agree to OrkDM's <a href="https://orkdm.com/privacy">Privacy Policy</a>
        </label>
      </div>

      <div className="go-section">
        <div className="or-string">
          <p>or</p>
        </div>
        <a href="https://orkdm.com" className="link-button">
          <button>I already have a key</button>
        </a>
      </div>
    </div>
  );
};

export default CTASection;
