import React from 'react';
import orkdmLogo from './assets/orkdm_logo.png';
import './styles.css';

const TopBanner: React.FC = () => {
  return (
    <div className="top-banner">
      <img src={orkdmLogo} alt="OrkDM Logo" className="orkdm-logo" />
      <h1 className="big-title">OrkDM</h1>
      <p className="subtext">Play Dungeons & Dragons with your AI Dungeon Master</p>
    </div>
  );
};

export default TopBanner;
